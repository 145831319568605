import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["collapseControl", "body"]

  initialize() {
    // add collapse control
    if (!this.hasCollapseControlTarget) {
      const elem = document.createElement("div")
      elem.setAttribute("data-target", "menu.collapseControl")
      elem.setAttribute("data-action", "click->menu#toggle")
      elem.appendChild(document.createElement("span"))

      this.element.prepend(elem)
    }

    this.collapsed = this.collapsed
  }

  toggle(event) {
    event.preventDefault()
    this.collapse(!this.collapsed)
  }

  async collapse(collapse) {
    const body = this.bodyTarget
    const contentHeight = body.scrollHeight
    const currentHeight = body.getBoundingClientRect().height
    const targetHeight = collapse ? 0 : body.scrollHeight

    // wrap transition in a promise
    // transit from currentHeight to targetHeight
    // resolve promise after "transitionend" event
    const transition = () => {
      this.transiting = true
      body.style.height = `${currentHeight}px`
      const rect = body.getBoundingClientRect()

      return new Promise((resolve, reject) => {
        
        // callback after transition finished
        // remove listeners, reset body and update collapsed state
        const callback = () => {
          body.removeEventListener("transitionend", callback)
          body.style.transition = ""
          body.style.height = ""
          this.transiting = false 
          this.collapsed = collapse

          resolve(body)
        }

        // add event listener and start transition
        body.addEventListener("transitionend", callback)
        body.style.transition = `height ${this.transitionDuration} linear`;
        body.style.height = `${targetHeight}px`
      })
    }

    if (!this.transiting) {
      const result = await transition()
    }
  }

  get collapsed() {
    return this.data.get("collapsed") == "true" ? true : false
  }

  set collapsed(bool) {
    this.data.set("collapsed", bool)
    this.element.classList.toggle("--collapsed", bool)
    const icon = bool ? "+" : "–"
    this.collapseControlTarget.innerHTML = `<span>${icon}</span>`
  }

  get transiting() {
    return this.data.get("transiting") == "true" ? true : false
  }

  set transiting(bool) {
    this.data.set("transiting", bool)
    this.element.classList.toggle("--transiting", bool)
  }

  get transitionDuration() {
    return ".25s"
  }
}
