import { Controller } from "stimulus"
import StimulusReflex from "stimulus_reflex"

export default class extends Controller {

  connect() {
    StimulusReflex.register(this)
    console.log("connect uploader", this.element)
  }

  render() {
    this.stimulate("UploadReflex#render")
  }

  submit() {
    this.stimulate("UploadReflex#submit")
  }
}
