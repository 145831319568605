import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  static targets = ["option", "results", "spinner"]

  tags(event) {
    event.preventDefault()

    // collect tags from inputs or leave ags empty
    // if value of event.currenTarget is empty == do not filter
    const tags = new Array()

    this.optionTargets.forEach(elem => {
      if (elem.getAttribute("data-checked") == "true") { 
        tags.push(elem.dataset.value) 
      }
    })

    this.stimulate("Filter#tags", tags)
  }

  clear(event) {
    event.preventDefault()

    this.optionTargets.forEach(elem => {
      this.setOptionChecked(elem, false)
    })

    this.tags(event)
  }

  toggle(event) {
    event.preventDefault()
    const option = event.target
    this.setOptionChecked(option, !this.optionChecked(option))
  }

  optionChecked(elem) {
    return elem.getAttribute("data-checked") == "true" ? true : false
  }

  setOptionChecked(elem, bool) {
    elem.setAttribute("data-checked", bool)
  }

  // add spinner and hide direct children
  beforeTags(elem, reflex) {
    this.element.prepend(this.spinner)
  }

  // remove spinner and show direct children
  afterTags(elem, reflex) {
    if (this.hasSpinnerTarget) { 
      this.spinnerTarget.parentNode.removeChild(this.spinnerTarget) 
    }
  }

  get spinner() {
    const elem = document.createElement("span")
    elem.classList.add("spinner")
    elem.setAttribute("data-target", "filter.spinner")
    return elem
  }
}
